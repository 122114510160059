import Vue from "vue";
import axios from "axios";
import { Loading, MessageBox } from "element-ui";
import router from "../router";
const $vue = new Vue();
import Cookies from "js-cookie";

let loadingInstance;
let requestCount = 0;
// 显示loading
function showLoading() {
  if (requestCount === 0 && !loadingInstance) {
    loadingInstance = Loading.service({
      text: "加载中",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.8)",
      customClass:'el-loading-mask-custom'
    });
  }
  requestCount++;
}

// 关闭loading
function hideLoading() {
  if (requestCount > 0) {
    requestCount--;
  }
  if (requestCount === 0 && loadingInstance) {
    loadingInstance.close();
    loadingInstance = null; // 清空loadingInstance
  }
}

//axios.defaults.withCredentials = true;
var server = axios.create({
  baseURL: "/api",
});
server.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    const uid = Cookies.get('uid');
    if (token) {
      // 如果token存在，则添加到请求头中
      config.headers["token"] = token;
    }else {
      if(uid) {
        config.headers['uid'] = uid;
      }
    }
    return config;
  },
  (error) => {
    throw new Error(error);
  }
);

server.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，可以正常拿到数据
    if (response.data.success) {
      return Promise.resolve(response.data.data);
    } else {
      MessageBox({
        title: "提示",
        message: response.data.message || "系统开了个小差，请稍后重试",
      });
      return Promise.reject(response.data.message);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          location.href = "/#/login";
          break;

        case 502:
          MessageBox({
            title: "提示",
            message: "系统繁忙，请稍后再试",
          });
          break;
        case 500:
          MessageBox({
            title: "提示",
            message: "系统在维护中，请稍后再试",
          });
          break;
        // 404请求不存在
        case 404:
          MessageBox({
            title: "提示",
            message: "接口不存在",
          });
          break;
        default:
          MessageBox({
            title: "提示",
            message: response.data.message || "系统开了个小差，请稍后重试",
          });
          break;
      }
      return Promise.reject(
        error.response.data.message || "系统开了个小差，请稍后重试"
      );
    }
  }
);

let serverWithLoading = axios.create({
  baseURL: "/api",
  timeout: 60000,
});

serverWithLoading.interceptors.request.use(
  (config) => {
    // loadingTimer = setTimeout(() => {

    // }, 200); // 延迟200毫秒显示加载器
    // loadingInstance = Loading.service({
    //     text: "加载中",
    //     spinner: "el-icon-loading",
    //     background: "rgba(0, 0, 0, 0.8)"
    // });
    const token = Cookies.get("token");
    const uid = Cookies.get('uid');
    if (token) {
      // 如果token存在，则添加到请求头中
      config.headers["token"] = token;
    }else {
      if(uid) {
        config.headers['uid'] = uid;
      }
    }

    return config;
  },
  (error) => {
    clearTimeout(loadingTimer);
    if (loadingInstance) loadingInstance.close();
    throw new Error(error);
  }
);

serverWithLoading.interceptors.response.use(
  (response) => {
    loadingInstance && loadingInstance.close();
    // 如果返回的状态码为200，可以正常拿到数据
    if (response.data.success) {
      console.log(response.data);
      return Promise.resolve(response.data.data);
    } else {
      MessageBox({
        title: "error",
        message: response.data.message || "系统开了个小差，请稍后重试",
      });
      return Promise.reject(response.data.message);
    }
  },
  (error) => {
    loadingInstance && loadingInstance.close();
    console.log(error);
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          location.href = "/#/login";
          break;

        case 502:
          MessageBox({
            title: "提示",
            message: "系统繁忙，请稍后再试",
          });
          break;
        case 500:
          MessageBox({
            title: "提示",
            message: "系统在维护中，请稍后再试",
          });
          break;
        // 404请求不存在
        case 404:
          MessageBox({
            title: "提示",
            message: "接口不存在",
          });
          break;
        default:
          MessageBox({
            title: "提示",
            message: response.data.message || "系统开了个小差，请稍后重试",
          });
          break;
      }
      return Promise.reject(
        error.response.data.message || "系统开了个小差，请稍后重试"
      );
    }
  }
);

let serverHasErrorWithLoading = axios.create({
  baseURL: "/api",
  timeout: 60000,
});

serverHasErrorWithLoading.interceptors.request.use(
  (config) => {
    // loadingInstance = Loading.service({
    //     text: "加载中",
    //     spinner: "el-icon-loading",
    //     background: "rgba(0, 0, 0, 0.8)"
    // });
    showLoading(); // 请求开始时调用
    const token = Cookies.get("token");
    const uid = Cookies.get('uid');
    if (token) {
      // 如果token存在，则添加到请求头中
      config.headers["token"] = token;
    }else {
      if(uid) {
        config.headers['uid'] = uid;
      }
    }
    return config;
  },
  (error) => {
    hideLoading(); // 请求错误时调用
    throw new Error(error);
  }
);

serverHasErrorWithLoading.interceptors.response.use(
  (response) => {
    // loadingInstance && loadingInstance.close();
    hideLoading(); // 请求成功时调用
    // 如果返回的状态码为200，可以正常拿到数据
    if (response.data.success) {
      return Promise.resolve(response.data);
    } else {
      MessageBox({
        title: "提示",
        message: response.data.message || "系统开了个小差，请稍后重试",
      });
      return Promise.reject(response.data.message);
    }
  },
  (error) => {
    // loadingInstance && loadingInstance.close();
    hideLoading(); // 请求成功时调用
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          location.href = "/#/login";
          break;

        case 502:
          MessageBox({
            title: "提示",
            message: "系统繁忙，请稍后再试",
          });
          break;
        case 500:
          MessageBox({
            title: "提示",
            message: "系统在维护中，请稍后再试",
          });
          break;
        // 404请求不存在
        case 404:
          MessageBox({
            title: "提示",
            message: "接口不存在",
          });
          break;
        default:
          MessageBox({
            title: "提示",
            message: response.data.message || "系统开了个小差，请稍后重试",
          });
          break;
      }
      return Promise.reject(
        error.response.data.message || "系统开了个小差，请稍后重试"
      );
    }
  }
);

const ajax = {
  post: (url, data, config) => {
    return server.post(url, data, config);
  },
  postWithLoading: (url, data, config) => {
    return serverWithLoading.post(url, data, config);
  },
  postWithLoadingHasError: (url, data, config) => {
    return serverHasErrorWithLoading.post(url, data, config);
  },
  get: (url, data, config) => {
    return server.get(url, data, config);
  },
  getWithLoading: (url, data, config) => {
    return serverWithLoading.get(url, data, config);
  },
  getWithLoadingHasError: (url, data, config) => {
    return serverHasErrorWithLoading.get(url, data, config);
  },
  put: (url, data, config) => {
    return server.put(url, data, config);
  },
  putWithLoading: (url, data, config) => {
    return serverWithLoading.put(url, data, config);
  },
  putWithLoadingHasError: (url, data, config) => {
    return serverHasErrorWithLoading.put(url, data, config);
  },
  delete: (url, data, config) => {
    return server.delete(url, data, config);
  },
  deleteWithLoading: (url, data, config) => {
    return serverWithLoading.delete(url, data, config);
  },
  deleteWithLoadingHasError: (url, data, config) => {
    return serverHasErrorWithLoading.delete(url, data, config);
  },
};

export default ajax;
