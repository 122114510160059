var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "header-right" },
        [
          _c(
            "div",
            { staticClass: "user-info" },
            [
              _c(
                "el-dropdown",
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v(" " + _vm._s(_vm.userName)),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.personalData($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v("Profile")])]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.modifyPassword($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v("Change Password")])]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v("Logout")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-avatar", {
            staticClass: "avatar",
            attrs: { src: _vm.avatar },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          style: { borderRadius: "10px" },
          attrs: {
            title: "change password",
            visible: _vm.dialogFormVisible,
            "append-to-body": "",
            width: "20%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Old", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { "show-password": "", autocomplete: "off" },
                    model: {
                      value: _vm.form.oldPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "oldPwd", $$v)
                      },
                      expression: "form.oldPwd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "new", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { "show-password": "", autocomplete: "off" },
                    model: {
                      value: _vm.form.newPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "newPwd", $$v)
                      },
                      expression: "form.newPwd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.changePwd($event)
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          style: { borderRadius: "20px" },
          attrs: {
            title: "Profile",
            visible: _vm.dialogFormVisible1,
            "append-to-body": "",
            width: "45%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible1 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form1, "label-width": _vm.formLabelWidth } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "avatar-upload-item",
                  attrs: { label: "Avatar" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "avatar-upload-container" },
                    [
                      _c("el-avatar", {
                        attrs: {
                          size: 100,
                          fit: "fill",
                          src: _vm.form1.faceUrl,
                        },
                      }),
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.UploadFileUrl,
                            "on-success": _vm.handleSuccess,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("click upload")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                " only support jpg/png，and less than 500kb "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { staticClass: "form-item", attrs: { label: "No." } },
                [_c("span", [_vm._v(_vm._s(_vm.form1.code))])]
              ),
              _c(
                "el-form-item",
                { staticClass: "form-item", attrs: { label: "Name" } },
                [_c("span", [_vm._v(_vm._s(_vm.form1.name))])]
              ),
              _c(
                "el-form-item",
                { staticClass: "form-item", attrs: { label: "Email" } },
                [_c("span", [_vm._v(_vm._s(_vm.form1.email))])]
              ),
              _c(
                "el-form-item",
                { staticClass: "form-item", attrs: { label: "Tel" } },
                [_c("span", [_vm._v(_vm._s(_vm.form1.phone))])]
              ),
              _c(
                "el-form-item",
                { staticClass: "form-item", attrs: { label: "Depart" } },
                [
                  _c(
                    "div",
                    _vm._l(_vm.form1.depts, function (dept, index) {
                      return _c(
                        "el-tag",
                        {
                          key: index,
                          staticClass: "tag-item",
                          attrs: { type: "success" },
                        },
                        [_vm._v(" " + _vm._s(dept.name) + " ")]
                      )
                    }),
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { staticClass: "form-item", attrs: { label: "Role" } },
                [
                  _c(
                    "div",
                    _vm._l(_vm.form1.roles, function (role, index) {
                      return _c(
                        "el-tag",
                        {
                          key: index,
                          staticClass: "tag-item",
                          attrs: { type: "warning" },
                        },
                        [_vm._v(" " + _vm._s(role.name) + " ")]
                      )
                    }),
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible1 = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "消息中心 " + (_vm.loading ? "加载中..." : ""),
            visible: _vm.drawer,
            "append-to-body": "",
            direction: "rtl",
            size: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "drawer-title" }, [
                    _vm._v("消息中心"),
                  ]),
                  _c("el-button", {
                    staticClass: "title-refresh-button",
                    attrs: { size: "medium", icon: "el-icon-refresh" },
                    on: { click: _vm.refreshMessages },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "message-container" },
            [
              _c(
                "el-row",
                {
                  staticClass: "btn-wrap",
                  attrs: { gutter: 10, type: "flex", justify: "end" },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.clearMsgs } },
                    [_vm._v("清除未读")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "message-list", attrs: { shadow: "never" } },
                _vm._l(_vm.visibleMessages, function (message) {
                  return _c(
                    "div",
                    {
                      key: message.id,
                      staticClass: "message-item",
                      attrs: { shadow: "never" },
                      on: {
                        click: function ($event) {
                          return _vm.handleReadMsg(message)
                        },
                      },
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: message.readStatus !== 1,
                            expression: "message.readStatus !== 1",
                          },
                        ],
                        staticClass: "red-dot",
                      }),
                      _c("div", { staticClass: "message-header" }, [
                        _c("h3", [_vm._v(" " + _vm._s(message.name) + " ")]),
                        _c("span", { staticClass: "message-timestamp" }, [
                          _vm._v(_vm._s(message.occurTimeStr)),
                        ]),
                      ]),
                      _c("p", [_vm._v(_vm._s(message.content))]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm.hasMore
            ? _c(
                "el-button",
                { staticClass: "load-more", on: { click: _vm.loadMore } },
                [_vm._v("加载更多")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }