import Vue from "vue";
import Router from "vue-router";
export * from "./dynamicRoutes";

Vue.use(Router);

/* Layout */
import Layout from "@/layout";

export const constantRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },

  {
    path: "/activate",
    name: "activate",
    component: () => import("@/views/login/activate"),
    hidden: true,
  },

  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  {
    path: "/createQuotationForm",
    name: "createQuotationForm",
    component: () => import("@/views/order/h5/createQuotationForm.vue"),
    hidden: true,
  },
  {
    path: "/createInquiryToCustomer",
    name: "createInquiryToCustomer",
    component: () => import("@/views/order/h5/createInquiryToCustomer"),
    hidden: true,
  },
  {
    path: "/confirmQuotation",
    name: "confirmQuotation",
    component: () => import("@/views/order/h5/confirmQuotation"),
    hidden: true,
  },
  {
    path: "/reconfirmQuotationForm",
    name: "reconfirmQuotationForm",
    component: () => import("@/views/order/h5/reconfirmQuotationForm"),
    hidden: true,
  },
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/index"),
        meta: { title: "Dashboard", icon: "dashboard", showNavBar: -1 },
      },
    ],
  },
  {
    path: "/InquiryToCustomer",
    name: "InquiryToCustomer",
    component: () => import("@/views/order/InquiryToCustomer"),
    hidden: true,
  },
  {
    path: "/QuotationToCompany",
    name: "QuotationToCompany",
    component: () => import("@/views/order/QuotationToCompany"),
    hidden: true,
  },
  {
    path: "/FinalUnderwritingInformationConfirm",
    name: "FinalUnderwritingInformationConfirm",
    component: () =>
      import("@/views/order/FinalUnderwritingInformationConfirm"),
    hidden: true,
  },

  {
    path: "/QuotationFormToInsuranceCompany",
    name: "QuotationFormToInsuranceCompany",
    component: () =>
      import("@/views/order/components/QuotationFormToInsuranceCompany"),
    hidden: true,
  },
  // {
  //   path: "/QuotationToCustomerPreview",
  //   name: "QuotationToCustomerPreview",
  //   component: () => import("@/views/order/QuotationToCustomerPreview"),
  //   hidden: true,
  // },

  // {
  //   path: '/',
  //   component: Layout,
  //   children: [{
  //     path: '/template',
  //     name: 'Template',
  //     component: () => import('@/views/template'),
  //     meta: { title: '模版配置', icon: 'dashboard', showNavBar: -1}
  //   }]
  // },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
